.n-navbars {
    padding: 0px;
    height: 80px;
    display: flex;
}

.solidIcons {
    display: none;
    position: relative;
    right: 25px;
    top: -15px;
}

.solidIcons i:nth-child(2) {
    visibility: hidden;
    opacity: 0;
}

.solidIcons2 {
    position: absolute;
    top: 25px;
    right: 60px;
} 

.solidIcons i {
    position: absolute;
    font-size: 30px;

}

nav {
    top: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 50px 0px 50px;
    z-index: 99;
    background-color: #fff;
    height: 80px;
    box-shadow: 1px 8px 20px rgba(0, 0, 0, 0.3);
    width: 100%;
}

.logo {
    height: 35px;
    width: 163px;
    left: 83.9453125px;
    top: 21.09765625px;
    border-radius: 0px;
}

.logo a img {
    width: 100%;
    height: 100%;
    margin-top: -10px;
    object-fit: contain;
}

#navbar {
  display: flex;
}

#navbar .n-li {
    display: flex;
    align-items: center;
}

#navbar .n-li li a {
    text-decoration: none;
}

#navbar .n-li-n {
    margin-left: 50px;
    cursor: pointer;
}

.language button {
    border: none;
    background: none;
}

.language {
    background: none;
}

.language img { 
    margin-right: 5px;
}

.n-nav ul li {
    position: relative;
    margin: 0 30px;
    Height: 30px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
}

.n-nav ul li a {
    color: #FC746C;
    transition: 0.5s ease-in-out;
}

.n-nav ul li a:hover {
    color: #FC746C;
}  

.n-nav ul li a:hover::after,
.n-nav ul li a.actives::after {
    content: "";
    width: 100%;
    height: 3px;
    background-color: #FC746C;
    position: absolute;
    bottom: 1px;
    right: 1px;
}  

#mobile {
    position: relative;
    display: none;
    width: 40px;
}

#mobile i {
    position: absolute;
    color: #FC746C;
    font-size: 30px;
    margin: -10px 0px 0 0;
}

.n-btn {
    background-color: #FC746C;
    color: #fff !important;
    padding: 15px 30px;
    border-radius: 12px;
}




@media screen and (max-width: 1280px) {
    .n-btn {
        padding: 10px 20px;
    }
    #navbar .n-li-n {
        margin-left: 60px;
    }
    .n-nav ul li {
        margin: 0 15px;
    }
    nav {
        padding: 0 2rem;
    }
}

@media (min-width: 700px) and (max-width: 950px) {
    .solidIcons2 {
        position: absolute;
        top: 25px;
        right: 110px;
    } 

}

@media screen and (max-width: 950px) {
    .solidIcons {
        display: block;
    }
    .responsiveUL {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 0px;
        left: 0;
        width: 110%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        box-shadow: 0 20px 30px rgba(0, 0, 0, 0.5);
        transition: 0.5s ease-in-out;
        z-index: 999999999;
        gap: 50px;
        transform: translateX(-100%);
    }
    .language button {
        color: #fff;
    }
    .responsiveNav {
        transform: none;
    }
    .n-nav ul li {
        margin-bottom: 20px;
    }
    #mobile {
        display: block;
    }
    #navbar .n-li {
        display: block;
    }
    #navbar .n-li-n {
        margin-left: 0px;
    }
}