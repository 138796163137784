
.contact {
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
}

.c-contact {
    margin-top: 100px;
}

.c-contact h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 62px;
    color: #000000;
}

.c-contact p {
    width: 506px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #777776;
}

.c-icons img {
    width: 25px;
    height: 25px;
    object-fit: cover;
    margin: -6px 10px 0 0;
}

/* .c-contact img:nth-child(2) {
    margin-right: 50px;

} */

.c-icons span {
    width: 66px;
    height: 27px;
    left: 108px;
    top: 480px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #000000;
}

.c-icons p {
    height: 21px;
    font-style: normal; 
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin: 5px 0 40px 35px;
    color: #212B36;
}


/***** contact-form *****/

.contact-form {
    width: 100%;
    height: 610px;
    background: #F7F7F7;
    box-shadow: 0px 16px 32px rgba(165, 165, 165, 0.25);
    border-radius: 5px;
    padding: 60px 20px 0px 20px;
    margin: 60px 0;
}

.contact-form h6 {
    width: 69.73px;
    height: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #000000;
}

.contact-form input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 5px 15px;
    outline: none;
}

input::placeholder {
    font-weight: 400;
    font-size: 12px;
    color: #6C757D;
}

.form-top {
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
    margin-bottom: -40px;
}

label {
    width: 100%;
}

label textarea {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    padding: 20px 15px;
    outline: none;
}

.form-top label {
   width: 100%;
}

.form-top img {
    width: 100%;
}

.contact-form button {
    float: right;
    margin: 10px 10px 0 0;
}

.contact-form-btn {
    color: #fff; 
    padding: 9px 41.6875px 9px 41.3125px;
    border: none;
    width: 140px;
    height: 42px;
    background: #FC746C;
    border-radius: 4px;
}

.contact-form-btn2 {
    color: #A7A9AC; 
    padding: 9px 41.6875px 9px 41.3125px;
    border: none;
    width: 150px;
    height: 42px;
    background: none;
}

@media screen and (max-width: 950px) {
    .contact {
        display: grid;
        grid-template-columns: repeat(1 , 1fr);
    }
    .contact-form {
        width: 98%;
    }
    .form-top {
        display: grid;
        grid-template-columns: repeat(1 , 1fr);
        margin-bottom: -20px;
    }
    .form-top img {
        display: none;
    }
    .c-contact h2 {
        font-weight: 600;
        font-size: 30px;
    }
    .c-contact p {
        width: 340px;
        font-weight: 300;
        font-size: 18px;
    }
}

@media screen and (max-width: 400px) {
    .contact-form {
        width: 97%;
    }
}