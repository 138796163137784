.popUp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0 , 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
}

.popUpInner {
    position: relative;
    overflow-y: auto;
    width: 80%;
    height: 85%;
    background-color: #fff;
    border-radius: 15px;
}

.popUpInner::-webkit-scrollbar {
    width: 0;
}

.popUpInner .close {
    position: absolute;
    top: 16px;
    right: 16px;
}

.popupTop {
   position: absolute;
   width: 100%;
   top: 0;
   left: 0;
   background-color: #F8F8FC;
   border-radius: 15px 15px 0 0;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 10px 30px;
   margin-bottom: 50px;
}

.popupTop h4 {
    font-size: 20px;
    color: #FC746C;
}

.popupTop img {
    width: 30px;
    height: 30px;
    cursor: pointer;
    object-fit: cover;
}

.popupCeanter {
    position: relative;
    top: 50px;
    margin: 20px;
    padding: 20px;
    background-color: #F8F8FC;
    border-radius: 15px;
}

.popupCeanter h6 {
    font-size: 20px;
    margin-bottom: 30px;
}

.fillNames {
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
    gap: 40px;
}

.popupCeanter h6 span {
    color: #FC746C;
}

.colorFill {
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    width: 100%;
    gap: 20px;
}

.colorFillPart {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.colorFillPart span {
    margin-bottom: 15px;
    font-weight: bold;
}

.colorFillPart b {
    color: #FC746C;
}

.colorFillPart input {
    width: 100%;
    height: 48px;
    gap: 10px;
    background: #FFFFFF;
    border: 1px solid #858C94;
    border-radius: 8px;
    padding: 0 0 0 60px;
    margin: 0 40px 10px 0px;
}

.colorFillPart img {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50px;
    left: 15px;
    object-fit: cover;
}

.partInputs {
    width: 70% !important;
}

.colorFillPart2 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border-bottom: 1px solid #858C94;
    border-radius: 8px;
    margin-top: 40px;
}


.colorFillPart2 .rightImg img {
    width: 40px;
    height: 40px;
}

.colorFillPart2 .rightIcon img {
    position: absolute;
    top: 10px;
    cursor: pointer;
    object-fit: cover;
}

/********/

.colorFillPart2 .rightIcon p {
    position: absolute;
    font-size: 14px;
    color: #635C63;
    bottom: 0px;
    left: 35%;
}

.erorrs {
    color: red;
    font-size: 14px;
    font-weight: 400;
}

/*******/

.HRpopUp::before {
    position: absolute;
    content: "";
    width: 96%;
    height: 1px;
    background-color: #FC746C;
}

.colorFillPart3 {
    width: 100%;
}

.colorFillPart4 {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.colorFillPart4 input {
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #858C94;
    border-radius: 8px;
    padding: 0 0 0 60px;
    margin: 0 40px 40px 0px;
}

.colorFillPart4 img {
    position: absolute;
    width: 35px;
    height: 35px;
    object-fit: cover;
    top: 30px;
    left: 10px;
}

.fillAddress {
    margin-top: 40px;
}

.poupBtn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.poupBtn button {
    width: 260px;
    height: 46px;
    box-shadow: 0px 0px 8px rgba(16, 16, 56, 0.25);
    border-radius: 10px;
    border: none;
    margin: 30px;
    color: #757575;
}

.poupFillBtn {
    background-color: #FC746C;
    color: #fff !important;
}

/********** AddStor2 **********/

.popAddSt {
    text-align: center;
    margin-top: 80px;
}

.popAddSt h4 {
    color: #FC746C;
}

.popAddSt img {
    width: 50%;
    height: 40%;
    object-fit: cover;
}

.popAddSt2 img {
    width: 35%;
}

/********** AddProduct **********/

.fillNames.fillNames2 {
    display: flex;
    flex-direction: column;
}

.addProductTop {
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
    gap: 40px;
}

.boxAddProduct {
   width: 90%;
   height: 200px;
   background-color: #fff;
   border-radius: 15px;
   margin: 30px 0;
   display: flex;
   padding: 20px;
}

/* .boxAddProduct input {
    width: 90%;
} */

.boxAddProduct2 {
    width: 350px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    background-color: #ECEFF1;
    border-radius: 15px;
}

.boxAddProduct2 img {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.boxAddProduct2 p {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #858C94;
}

.boxAddProduct2 span {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-transform: lowercase;
    color: #B3B3B3;
}

.ProductImgs {
    margin-left: 20px;
    width: 80px;
    height: 70px;
    background-color: #ECEFF1;
    border-radius: 15px;
    border:  7px solid #fff;
    outline: 2px dashed #635C63;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ProductImgs img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin: 20px;
}

.productName {
    margin-top: 30px;
}

.form-check-input {
    color: #FC746C !important;
    background-color: #FC746C !important;
    border: 1px solid #FC746C !important;
    outline: 3px solid #FC746C !important;
}

.form-check label {
    font-style: normal;
    font-weight: 500;
    font-size: 12.5px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: #000000;
}

@media (max-width: 550px) {
    .colorFill {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        gap: 20px;
    }
    .popUpInner {
        width: 99%;
        height: 85%;
    }
    .colorFillPart2 {
        margin: 30px 0;
    }
    .partInputs {
        width: 100% !important;
    }
    .colorFillPart input {
        width: 100%;
    }
    .fillNames {
        display: grid;
        grid-template-columns: 1fr;
        gap: 40px;
    }
    .colorFillPart2 .rightIcon p {
        position: absolute;
        font-size: 10px;
        left: 30%;
    }
    .colorFillPart2 .rightImg img {
        width: 30px;
        height: 30px;
    }
    .addProductTop {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0px;
    }

    .boxAddProduct {
        width: 50%;
        height: 200px;
        margin: 10px 0;
        padding: 10px;
     }
     .colorFillPart3 {
        width: 100%;
    }
    .boxAddProduct {
        width: 100%;
        height: 120px;
        margin: 20px 0;
        padding: 10px;
    }
    .boxAddProduct2 {
        width: 200px;
        height: 100px;
        padding: 10px;
    }
    .ProductImgs {
        margin-left: 10px;
        width: 60px;
        height: 50px;
    }
    
    .ProductImgs img {
        width: 30px;
        height: 30px;
        margin: 10px;
    }
    .boxAddProduct2 img {
        width: 30px;
        height: 30px;
    }
    
    .boxAddProduct2 p {
        font-weight: 400;
        font-size: 10px;
        line-height: 10px;
    }
    .boxAddProduct2 span {
        font-weight: 200;
        font-size: 9px;
        line-height: 10px;
        text-align: center;
    }
    .poupBtn button {
        width: 150px;
        height: 40px;
        margin: 5px;
        font-size: 14px;
    }
    .popAddSt img {
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 500px) {
    .fillImgs img {
        margin-top: 23px;
    }
}

@media (min-width: 768px) and (max-width: 922px) {
    .fillImgs img {
        margin-top: 23px;
    }
    .colorFillPart2 {
        margin-top: 60px;
    }
    .colorFillPart2 .rightIcon p {
        position: absolute;
        font-size: 10px;
        left: 30%;
    }
    
    .colorFillPart2 .rightImg img {
        width: 30px;
        height: 30px;
    }
    .colorFillPart3 {
        width: 100%;
    }
    .colorFillPart input {
        width: 100%;
    }
    .boxAddProduct {
        width: 100%;
        height: 120px;
        margin: 20px 0;
        padding: 10px;
    }
    .boxAddProduct2 {
        width: 200px;
        height: 100px;
        padding: 10px;
    }
    .ProductImgs {
        margin-left: 10px;
        width: 60px;
        height: 50px;
    }
    
    .ProductImgs img {
        width: 30px;
        height: 30px;
        margin: 10px;
    }
    .boxAddProduct2 img {
        width: 30px;
        height: 30px;
    }
    
    .boxAddProduct2 p {
        font-weight: 400;
        font-size: 10px;
        line-height: 10px;
    }
    .boxAddProduct2 span {
        font-weight: 200;
        font-size: 9px;
        line-height: 10px;
        text-align: center;
    }
    .popAddSt img {
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 768px) and (max-width: 820px) {
    .colorFillPart3 .imgPsh {
        position: absolute;
        top: 74px !important;
    }
}