.slider-container {
    padding: 0 15%;
  }

.slider {
background: #FFFFFF;
box-shadow: 0px 2px 20px rgba(170, 170, 170, 0.47);
border-radius: 8px;
padding: 20px;
}  

.n-slider p {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    padding: 0 150px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #777777;
}

.s-star {
    margin-top: 20px;
}

.star img {
    margin-right: 8px;
}

.s-slider-left {
    width: 100px;
    height: 100px;
}

.s-slider-left img {
    width: 100%;
    height: 100%;
}

@media (max-width: 950px) {
    .slider-container {
        padding: 0 4%;
    }
   .slider {
       padding: 5px;
    }
    .n-slider p {
        padding: 0px;
    }
    .s-slider-left {
        width: 70px;
        height: 70px;
        margin-top: 25px;
    }
}

@media (max-width: 550px) {
    .slider {
       width: 325px;
       margin: auto;
    }  
}

@media (max-width: 360px) {
    .slider {
        width: 310px;
        margin: auto;
     }  
}