.word {
    position: relative;
    background-image: url("../../../images/لقطة الشاشة 2023-06-26 143121.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 364px;
    left: 0px;
    right: 0px;
    box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.19);
}

.w-word {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 70px;
    color: #FFFFFF;
}

.wordCeanter {
    text-align: center;
}

.wordCeanter h3 {
    margin-bottom: 30px;
}

.wordbtn{
    background: #FFFFFF;
    box-shadow: 0px 0px 35px rgba(255, 255, 255, 0.8);
    Width : 220px;
    text-align: center;
    color: #FC746C;
    border: none;
    border-radius: 6px;
    padding: 15px 0;
    cursor: pointer;
}

@media (max-width: 800px) {

}

@media (max-width: 500px) {

}