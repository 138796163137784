.card-main {
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    gap: 1;
    width: calc(100% - calc(270px));
    float: right;
    padding-top: 50px;
    background-color: #f3f6f9;
}

.card-main-prodact {
    margin: 10px 10px 50px 10px;
    background: #ffffff;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    margin: 40px auto;
}

.h-box {
    display: flex;
    justify-content: space-between;
}

.box-namber {
    margin-top: 10px;
    text-align: center;
}

.h-box h4 {
    font-weight: bold;
    margin-left: 40px;
}

.box-namber hr {
    width: 100%;
    margin-left: 20px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.25;
}

.box-namber .child-les {
    width: 100%;
    color: #a4a8ba;
    margin-left: 20px;
}

.h-box-left span {
    color: green;
}

.div-icon {
    width: 100%;
    height: 64px;
    margin: -35px 0 0 20px;
    text-align: center;
    padding-top: 20px;
    color: #fff;
    background: #FD9E98;
    box-shadow: 0px 7px 10px -5px rgba(253, 158, 152, 0.4);
    border-radius: 12px;
}

.child-img {
    height: 100px;
    margin-top: 20px;
}

@media (max-width: 550px) {
    .card-main {
        grid-template-columns: repeat(1 , 1fr);
        gap: 0;
        width: calc(100% - calc(0px));
        float: right;
    }

}

@media (min-width: 550px) and (max-width: 850px) {
    .card-main {
        grid-template-columns: repeat(2 , 1fr);
        width: calc(100% - calc(0px));
    }

}

@media (max-width: 1024px) {
    .card-main {
        gap: 0;
        width: calc(100% - calc(0px));
    }

}