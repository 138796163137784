* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}

body {
  font-family: sans-serif;
}

.container {
  padding: 0 7.5%;
} 

.containers {
  padding: 0 4%;
} 

.internal-container {
  padding: 0 0 0 270px;
} 

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 50px 0;
}

.title p {
  width: 70%;
  color: #6C757D;
  margin-top: 25px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}

/***** Slider *****/

.sliders {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 2px 20px rgba(170, 170, 170, 0.47);
  border-radius: 8px;
  height: 194.01px;
  margin-bottom: 50px;
}

.s-slider {
  display: flex;
  justify-content: space-between;
}

.star {
  display: flex;
}

.h-btn {
  margin-top: 2rem;
  background-color: #FC746C;
  padding: 15px 27px;
  color: #fff;
  border-radius: 12px;
  cursor: pointer;
}

.h-btn {
 
  border: none;
  background: none;
  color: #fff;
  padding-right: 20px;
  cursor: pointer;
}

.submit {
  width: 220px;
  color: #fff;
  background-color: #FC746C;
  border: none;
  border-radius: 6px;
  padding: 15px 0;
  cursor: pointer;
} 

.h-btn i {
  font-size: 17px;
}

.description {
  background: linear-gradient(to top,  #FC746C 96% , #302a2a 4%);
  border-top: 1px solid rgba(0, 0, 0, 0.8);
}

/*************** conditions **************/

.rectangle {
  background-image: linear-gradient(
         
  rgba(0, 0, 0, 0.8) 0%,
  rgba(0, 0, 0, 0.8) 100%
),
url("./images/YXNzZXRzL2Jhbm5lcnMvWmFqaWwtQnVzaW5lc3MuanBn 1.jpg");
  background-color: rgba(0, 0, 0, 0.9);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.privacy {
  background-image: linear-gradient(
         
  rgba(0, 0, 0, 0.5) 0%,
  rgba(0, 0, 0, 0.5) 100%
),
url("./images/YXNzZXRzL2Jhbm5lcnMswtQnVzaW5lc3MuanBn 1.jpg");
  background-color: rgba(0, 0, 0, 0.9);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

/************* blog start *************/

.blog {
  background-image: linear-gradient(
         
  rgba(0, 0, 0, 0.9) 0%,
  rgba(0, 0, 0, 0.9) 100%
),
url("./images/blueprints-1837238_1920.jpg");
  background-color: rgba(0, 0, 0, 0.9);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.blog p {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  text-transform: lowercase;
  text-align: center;
  color: #FFFFFF;
}

.c-rectangle input {
  width: 90%;
  margin: 70px 50px;  
  height: 38px;
  background: #FFFFFF;
  border: 1px solid #FC746C;
  border-radius: 20px;
  padding: 0 0px 3px 50px;
  outline: 1px solid #f3625a;
}

.b-scearch {
  position: relative;
}

.b-scearch i {
  position: absolute;
  left: 70px;
  top: 80px;
  color: #FC746C;
}

/************* blog end *************/

.rectangle h2,
.privacy h2,
.blog h2 {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 65px;
  text-align: center;
  padding-top: 11rem;
  letter-spacing: -1px;
  color: #FFFFFF;
}

.c-rectangle h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 65px;
  display: flex;
  align-items: center;
  letter-spacing: -1px;
  color: #FC746C;
}

.c-rectangle p {
  font-style: normal;
  font-weight: 100;
  font-size: 15px;
  line-height: 40px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  color: #000000;
}

.c-rectangle {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  margin-top: -200px;
}

.c-containers {
  padding: 0 4% 4% 4%;
} 

@media (max-width: 992px) {
  .rectangle h2 {

      font-weight: 400;
      font-size: 30px;
      line-height: 65px;
  }
  .c-rectangle p {
      width: 100%;
      font-weight: 100;
      font-size: 13px;
      line-height: 30px;
      margin-left: 0px;

  }
  .c-rectangle h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 22px;
      letter-spacing: 1px;
      margin-top: 30px;
  }
  .c-rectangle input {
    width: 100%;
    margin: 70px 0px;  
  }
  .b-scearch i {
    position: absolute;
    left: 20px;
    top: 80px;
  }
  .main-main {
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
 }
}

@media (max-width: 992px) {
  .container {
    padding: 0 2%;
  }
  .title p {
    width: 85%;
  }
}

@media (max-width: 550px) {
  .container {
    padding: 0 2%;
  }
  .title h1 {
    font-size: 20px;
  } 
  .title p {
    width: 100%;
    color: #6C757D;
    margin-top: 25px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
  }
}