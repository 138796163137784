* {
    padding: 0;
    margin: 0;
}

ul {
    margin-left: -32px;
}

.logos {
    position: relative;
    width: 270px;
    height: 75px;
    left: 0px;
    top: 0px;
    background: #F8F8FC;
}

.img-logo {
    position: absolute;
    width: 170px;
    height: 21.01px;
    top: 26px;
    left: 50px;
    object-fit: cover;
}

img {
    object-fit: cover;
}

.hCH {
    position: absolute;
    font-size: 30px;
    left: 225px;
    top: 15px;
    cursor: pointer;
    display: none;
}

.menu-icon {
    display: none;
    padding-top: 18px;
    font-size: 25px;
    opacity: 1;
    cursor: pointer;
    margin-left: 20px;
}

#nav-header {
    position: absolute;
    width: 270px;
    height: 1024px;
    background: #FFFFFF;
    box-shadow: 0px 12px 30px rgba(80, 143, 244, 0.1);
    z-index: 99999;
    transition: .5s ease-in-out;
}

#nav-header a {
    text-decoration: none;
    list-style: none;
}

.n-center {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 270px;
    height: 925px;
    left: 0px;
    top: 25px;
}

i {
    width: 13.98px;
    height: 13.11px;
    margin-left: 10px;
    margin-right: 30px;
    top: 26.47%;
    bottom: 34.97%;
    color: #fd9e98;
}

.n-loig {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.n-main {
    display: flex;
    align-items: center;
    width: 220px;
    height: 51px;
    padding: 30px 0px 30px 20px;
    margin-top: 17px;
    color: #4f726a;
    /* border: 1px solid #000; */
}

.active {
    /* position: absolute; */
    width: 100%;
    color: #FFFFFF;
    background-color: #fd9e98;
}

.active i {
    color: #FFFFFF;
}

.n-left {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.main-icone {
  display: flex;
  cursor: pointer;
}

.n-ico {
    position: relative;
}

.n-ico i {
   position: absolute;
    right: -20px;
    top: -5px;
    color: #000000cf;
}

.n-but {
    margin-bottom: 62px;
}

.dropDown {
    width: 210px;
    height: 130px;
    position: absolute;
    background-color: #fff;
    padding: 30px;
    border-radius: 15px;
    top: 65px;
    left: 55px;
    z-index: 999;
}

.dropDown2 {
    width: 210px;
    height: 90px;
    position: absolute;
    background-color: #fff;
    padding: 30px;
    border-radius: 15px;
    top: 65px;
    left: 55px;
    z-index: 999;

}

.dropDown2::before {
    position: absolute;
    content: "";
    width: 1px;
    height: 90px !important;
    background-color: #fd9e98;
    top: 0;
    left: 30px;
}

.dropDown::before {
    position: absolute;
    content: "";
    width: 1px;
    height: 130px;
    background-color: #fd9e98;
    top: 0;
    left: 30px;
}

.dropDownPath {
    margin-bottom: 20px;
    margin-left: 20px;
}

.dropDownPath a {
    color: #09101D;
    font-size: 17px;
}

.dropDownPath::before {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FC746C;
    left: 25.5px;
    margin-top: 8px;
}

.navs-headers {
    display: inline !important;
    position: absolute !important;
    left: 0 !important;
    z-index: 99999 !important;
    transition: 1s ease-in-out !important;
}

@media (max-width: 550px) {

}

@media (max-width: 1024px) {
    .menu-icon {
        display: block;
        padding-top: 18px;
        font-size: 25px;
        opacity: 1;
        cursor: pointer;
    }
    #nav-header {
        display: none;
    }
    .hCH {
        display: block;
    }
}