.doard {
    width: calc(100% - calc(270px));
    float: right;
    padding-top: 20px;
    background-color: #f3f6f9;
}

.doard-path {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.doard-path {
    margin: 20px;
}

.doard-path h3 {
    font-weight: bold;
}

.doard-path p {
    color: #959697;
}

.doard-path2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: #fff;
    padding-right: 30px;
    border-radius: 6px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2),
    -4px -4px 10px rgba(0, 0, 0, 0.1);
}

.doard-path2 i {
    color: rgba(226, 103, 97, 1);
    font-size: 14px;
} 

.doard-path2 p {
    color: #000;
    margin-top: 15px;
    font-size: 14px;
}

@media (max-width: 1024px) {
    .doard {
        width: calc(100% - calc(0px));
        float: right;
        margin-top: 20px;
    }
}