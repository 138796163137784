.header {
    position: relative;
    background-image: linear-gradient(
           
    rgba(252, 116, 108, 0.4) 0%,
    rgba(252, 116, 108, 0.6) 100%
 ),
 url("../../../images/photo_2023-05-30_13-33-41.jpg");
    background-color: rgba(0, 0, 0, 0.9);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
}

.h-center {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.h-center h1 {
    margin-top: 12rem;
    color: #fff;
    font-size: 35px;
}

.h-center p {
    margin-top: 2rem;
    font-size: 25px;
    color: #373744;
}

.h-namber {
    display: flex;
    margin-top: 2rem;
}

.h-namber h2 {
    color: #fff;
    font-size: 35px;
    font-weight: 100;
    margin-bottom: 0.5rem;
}

.h-namber span {
    color: #373744;
    font-weight: bold;
}

.h-namber .barth {
    margin: 0 1.7rem;
}

.fa-truck-fast {
    color: #fff;
}


@media screen and (max-width: 950px) {
    .h-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
     }
     .h-center h1 {
        margin-top: 20rem;
        color: #fff;
        font-size: 30px;
    }
    
    .h-center p {
        margin-top: 5rem;
        font-size: 20px;
        color: #373744;
    }
    .h-namber {
        display: flex;
        margin-top: 5rem;
    }
    
    .h-namber h2 {
        color: #fff;
        font-size: 30px;
        font-weight: 100;
        margin-bottom: 0.5rem;
    }
}

@media screen and (max-width: 550px) {
     .h-center h1 {
        margin-top: 10rem;
        font-size: 20px;
    }
    
    .h-center p {
        margin-top: 3rem;
        font-size: 15px;
    }
    .h-namber {
        margin-top: 3rem;
    }
    
    .h-namber h2 {
        font-size: 25px;
    }
    .h-namber .barth {
        margin: 0 1.3rem;
    }
}