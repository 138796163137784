.h-top {
    display: flex;
    align-items: center;
    float: right;
    margin-right: 0px !important;
    width: calc(100% - calc(270px));
    height: 88px;
    padding: 0 40px;
    background: linear-gradient(269.8deg, rgba(226, 104, 97, 0.8) -6.89%, rgba(252, 143, 137, 0.7) 26.51%, rgba(253, 170, 166, 0.869));
}

.hOH {
    position: absolute;
    font-size: 30px;
    color: #fff !important;
    top: 20px;
    left: 10px;
    cursor: pointer;
    display: none; 
}

.h-home {
    display: flex;
    justify-content: space-between;
}

.h-left {
    display: flex;
}

.h-name {
    margin: 0px 35px 0 0px;
    display: flex;
}

.h-name h5 {
    color: #F8F8FC;
    font-size: 26px;
    font-weight: 100;
}

.h-name h6 {
    color: rgba(226, 104, 97, 0.8);
    padding-left: 5px;
    margin-top: 10px;
}

.h-input {
    position: relative;
}

.h-input input {
    width: 310px;
    height: 40px;
    top: calc(50% - 40px/2);
    border: 2px solid #F8F8FC;
    border-radius: 20px;
    outline: none;
    background: none;
    padding: 20px;
    padding-left: 55px;
}

input::placeholder {
    font-size: 13px;
}

.fa-magnifying-glass {
    position: absolute;
    top: 15px;
    left: 5px;
    color: #a39497;
}

.h-icons i {
    position: relative;
     color: #F8F8FC;
     font-size: 22px;
}

.h-icons img {
    border-radius: 35%;
}

.h-icons .fa-globe {
    margin-right: 40px;
}

/**************/

.opens {
    position: absolute;
    top: 70px;
    right: 180px;
    width: 350px;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.56);
    border-radius: 15px;
    z-index: 9;
}



.opensName {
    position: absolute;
    top: 70px;
    right: 30px;
    width: 350px;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.56);
    border-radius: 15px;
    z-index: 9;
}

.open-icon {
    cursor: pointer;
}

.open-icon:hover .opensName {
    display: block;
    visibility: visible;
}

.notificationTitle {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0px;
    background: #EDEDF0;
    border-radius: 15px 15px 0px 0px;
}

.notificationTitle h4 {
    text-align: center;
    font-size: 20px;
    color: #FC746C;
    padding: 10px 0 5px 0px;
}

.notifications {
    margin-top: 45px;
}

.notificaPath {
    position: relative;
    padding: 10px;
}

.notificaPa,
.notificaPat {
    display: flex;
}

.notificaPa2 h6 {
   font-size: 20px !important;
   margin-top: 10px;
   margin-left: 20px;
}

.notificaPath img {
    width: 50px;
    height: 50px;
    margin-right: 8px;
    cursor: pointer;
} 

.notificaPath h6 {
    font-size: 15px;
    cursor: pointer;
}

.notificaPath p {
    font-size: 14px;
    color: #A7A9B7;
    cursor: pointer;
}

.notificaPath b::before  {
    position: absolute;
    bottom: 0;
    content: "";
    width: 93%;
    height: 2px;
    background-color: #A5A5A5;
}

.notificaPathNoti {
    display: flex;
    justify-content: space-between;
}

.notificaPathNoti span {
    position: absolute;
    font-size: 14px;
    color: #A7A9B7;
    width: 65px;
    right: 10px;
    margin: 0 0 0 10px;
    padding: 0 0 0 14px ;
    background-color: #fff;
    cursor: pointer;
}


/************* Searchs2 *************/

.h-name img {
    width: 50px;
    height: 35px;
}

@media (max-width: 1024px) {
    .h-input input {
        width: 200px;
        padding: 15px;
        padding-left: 35px;
        margin-left: 50px;
    }
    .fa-magnifying-glass {
        left: 52px;
        color: #a39497;
    }
    .h-top {
        padding: 0 10px;
    }
    .h-name {
        padding-left: 50px;
    } 
    .hOH {
        display: block; 
    }
    .h-top {
        width: calc(100% - calc(0px));
        padding: 0 0px;
    }
    .h-name img {
        display: none;
    }
}

@media (min-width: 700px) and (max-width: 1000px) {
    .h-icons {
        display: flex;
        float: right;
    }
    .h-icons i {
        width: 30px;
        color: #F8F8FC;
        font-size: 30px;
    }
    .h-icons .fa-globe {
        margin-right: 20px;
    }
    .h-icons img {
        margin-top: 8px;
        width: 35px;
        height: 35px;
    }
    .h-name {
        padding-left: 30px;
    } 
    .h-name img {
        display: none;
    }
}
 
@media (max-width: 550px) {
    .h-top {
        width: calc(100% - calc(0px));
        padding: 0 0px;
    }
    .h-name {
        padding-left: 20px;
        display: block;
        width: 160px;
    }
    .h-icons {
        display: flex;
        float: right;
    }
    .h-icons i {
        width: 30px;
        color: #F8F8FC;
        font-size: 22px;
    }
    .h-icons .fa-globe {
        margin-right: 0px;
    }
    .h-icons img {
        margin-top: 8px;
        width: 35px;
        height: 35px;
    }
    .fa-bell {
        margin-right: -14px;
    }
    .h-name h5 {
        font-size: 20px;
    }
    
    .h-name h6 {
        margin-top: 0px;
        font-size: 14px;
    }
    .opens {
        right: 0px;
        width: 350px;
    }
    .opensName {
        right: 0px;
        width: 300px;
    }
    .h-name {
        margin: 0px 0px 0 20px;
    }
    .h-input {
        display: none;
    }
    .h-name img {
        display: none;
    }
}